var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-question-wrapper"},[_c('div',{staticClass:"check-question-wrapper"},[_c('p',[_vm._v("Danh sách câu hỏi đã chọn")]),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"radio-wrapper"},_vm._l((_vm.checked_questions),function(item,index){return _c('span',{key:index,staticClass:"checkbox-item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.check),expression:"item.check"}],staticClass:"mr-2 checkbox-round",attrs:{"type":"checkbox","disabled":"","id":"vehicle1","name":"vehicle1"},domProps:{"checked":Array.isArray(item.check)?_vm._i(item.check,null)>-1:(item.check)},on:{"change":function($event){var $$a=item.check,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "check", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "check", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "check", $$c)}}}}),_c('label',{attrs:{"for":"vehicle1"}},[_vm._v("Câu "+_vm._s(item.index))]),_c('br')])}),0)]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{on:{"click":_vm.saveExamQuestion}},[_vm._v("Cập nhật đề thi")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h4',[_c('v-btn',{staticClass:"primary",on:{"click":_vm.goQuestionManager}},[_vm._v("Quản lý câu hỏi")])],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.groupQuestions},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.title)}})]}},{key:"item.group_question_type_id",fn:function(ref){
var item = ref.item;
return [(item.group_question_type_id === 1)?_c('div',[_vm._v("Nhóm chung")]):(item.group_question_type_id === 2)?_c('div',[_vm._v("Nhóm riêng")]):_vm._e()]}},{key:"item.list",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.fetchQuestionListBy(item.id)}}},[_vm._v(" câu hỏi ")])]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.questions_header,"items":_vm.questionsByGroup},scopedSlots:_vm._u([{key:"item.content",fn:function(ref){
var item = ref.item;
return [(!item.content)?_c('div',[_vm._v("Dạng câu hỏi không có tiêu đề")]):_c('div',{domProps:{"innerHTML":_vm._s(item.content)}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.viewQuestion(item)}}},[_vm._v("Xem chi tiết")])]}},{key:"item.check",fn:function(ref){
var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.check),expression:"item.check"}],attrs:{"id":"vehicle","name":"vehicle","type":"checkbox"},domProps:{"checked":Array.isArray(item.check)?_vm._i(item.check,null)>-1:(item.check)},on:{"change":[function($event){var $$a=item.check,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "check", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "check", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "check", $$c)}},function($event){return _vm.selectQuestion($event,item)}]}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }